<template>
  <div>
    <v-card-text>
      <v-container class="row1">
        <v-row>
          <v-col cols="14" sm="8" md="8">
            <v-row dense class="mb-5">
              <router-link
                v-bind:to="{
                  path: '/stores/zones/' + locationInfo.zoneId
                }"
                style="text-decoration: none;"
              >
                <v-btn rounded color="#05a2fa">
                  <v-icon color="white">
                    mdi-arrow-left
                  </v-icon>
                </v-btn>
              </router-link>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-row>
          <v-col cols="4" md="8" class="mx-auto">
            <v-card>
              <div style="float:right; margin-top:10px; margin-right:10px;">
                <v-icon
                  color="#05a2fa"
                  @click="editItem()"
                  style="margin-right:5px"
                >
                  mdi-pencil-outline
                </v-icon>
                <v-icon color="#FF0000" @click="deleteItem()">
                  mdi-trash-can-outline
                </v-icon>
              </div>
              <v-card-title>
                <!-- Zone Id : {{ locationInfo.zoneId }} <br />
                Location Id : {{ locationInfo.locationId }} <br /> -->
                Location Code : {{ locationInfo.locationCode }}  
              </v-card-title>
              <v-card-title style="font-size:20px">
                Location Name : {{ locationInfo.locationName }}
              </v-card-title>

              <v-divider class="mx-4"></v-divider>

              <v-card-text>
                Status :

                <v-chip
                  label
                  color="green"
                  class="white--text"
                  v-if="locationInfo.active === true"
                >
                  Active
                </v-chip>
                <v-chip
                  label
                  color="red"
                  class="white--text"
                  v-if="locationInfo.active === false"
                >
                  In Active
                </v-chip>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-dialog v-model="dialogEdit" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Edit Location</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="LocationForm">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedLocationInfo.locationName"
                      label="locationName"
                      outlined
                      clearable
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="editedLocationInfo.locationCode"
                      label="Ean"
                      outlined
                      clearable
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      v-model="editedLocationInfo.active"
                      :items="status"
                      item-text="text"
                      item-value="value"
                      label="Status"
                      outlined
                      
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      v-model="editedLocationInfo.zoneId"
                      :items="zones"
                      item-text="zoneId"
                      item-value="zoneId"
                      label="Zone Id"
                      outlined
                      
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="close">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="saveEdit"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="headline">
            Are you sure you want to delete this item?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="closeDelete">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="trashItem">
              OK
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </div>
</template>

<style>
.row1 {
  margin-top: -25px;
}
 
.inputNumber input[type="number"] {
  -moz-appearance: textfield;
}
.inputNumber input::-webkit-outer-spin-button,
.inputNumber input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>

<script>
export default {
  data() {
    return {
      dialogEdit: false,
      dialogDelete: false,
      dialogRestore: false,
      dialogTrash: false,
      search: "",
      items: [
        {
          text: "Events",
          disabled: false,
          href: "breadcrumbs_dashboard"
        },
        {
          text: "Event Name",
          disabled: false,
          href: "breadcrumbs_dashboard"
        },
        {
          text: "Balance Account",
          disabled: false,
          href: "breadcrumbs_link_1"
        }
      ],

      status: [
        { text: "Active", value: true },
        { text: "InActive", value: false }
      ],

      editedIndex: -1,
      editedLocationInfo: {
        wristbandNumber: "",
        customerAccountId: "",
        guestId: "",
        ean: "",
        active: true
      },
      defaultLocationInfo: {
        wristbandNumber: "",
        customerAccountId: "",
        guestId: "",
        ean: "",
        active: true
      },

      locationInfo: {},

      zones: []
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add New Wristband" : "Edit Wristband";
    }
  },
  watch: {
    dialogEdit(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },

  created() {
    this.getDataFromApi();
  },
  methods: {
    getDataFromApi() {
      var currentUrl = window.location.pathname;
      let vars = currentUrl.split("/");
      vars.reverse();
      let url = vars[0];
      url = parseInt(url);
      // getData = {
      //   locationId: 5
      // };
      this.$http.get(`/Location/GetById?LocationId=${url}`).then(response => {
        this.locationInfo = response.data.data;
      });
      this.$http.get(`/Zone`).then(response => {
        this.zones = response.data.data;
      });
    },

    editItem() {
      this.editedLocationInfo = Object.assign({}, this.locationInfo);
      this.dialogEdit = true;
    },

    deleteItem() {
      this.dialogDelete = true;
    },
    trashItem() {
      var id = this.locationInfo.zoneId;
      this.$http
        .post("/Location/Trash", this.locationInfo)
        .then(response => {
          if (response.data.success === true) {
            this.$toast.success("Deleted successfully.", "Success", {
              position: "topRight"
            });
            this.$router.push(`/stores/zones/${id}`);
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight"
            });
          }
        })
        .catch(error => {
          if (error.response.status) {
            this.$toast.error(" Deleting Process Failed", "Error", {
              position: "topRight"
            });
          }
        });
    },
    deleteItemConfirm() {
      var Id = this.editedLocationInfo.wristbandId;
      this.$http
        .delete("/Wristband/" + Id, null, {
          params: { Id: this.editedLocationInfo.id }
        })
        .then(response => {
          if (response.status === 200) {
            this.$toast.success("Wristband deleted successfully.", "Success", {
              position: "topRight"
            });
          }
        })

        .catch(error => {
          if (error.response.status === 404) {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight"
            });
          }
        });
      //this.users.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialogEdit = false;
      this.$nextTick(() => {
        this.editedLocationInfo = Object.assign({}, this.defaultLocationInfo);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedLocationInfo = Object.assign({}, this.defaultLocationInfo);
        this.editedIndex = -1;
      });
    },

    saveEdit() {
      if (this.$refs.LocationForm.validate()) {
        this.$http
          .patch("/Location/Update", this.editedLocationInfo)
          .then(response => {
            if (response.data.success === true) {
             this.locationInfo = response.data.data;

              this.$toast.success(
                "Wristband updated successfully.",
                "Success",
                {
                  position: "topRight"
                }
              );
            } else {
              this.$toast.error(response.data.message, "Error", {
                position: "topRight"
              });
            }
          })
          .catch(error => {
            if (error.response.status) {
              this.$toast.error(" Updating Process Failed", "Error", {
                position: "topRight"
              });
            }
          });

        this.dialogEdit = false;
      }
    },

    getStatusColor(data) {
      switch (data) {
        case "true":
          return "success";
        case "false":
          return "error";
        default:
          return "";
      }
    }
  }
};
</script>
